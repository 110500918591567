import {useState, useEffect, useMemo, useRef, createContext, useContext, useCallback} from 'react';
import Modal from 'react-bootstrap/Modal';
import {NotificationManager} from 'react-notifications';
import {
  checkAlreadyUploaded,
  submitReferences,
} from './api.js';

import logo from './uwork-logo-white.png';
import './App.css';

function logError(error) {
  if (error.response) {
    console.log('ERROR:\n', error.response);
  } else {
    console.log('ERROR:\n', error);
  }
}

function App() {
  const numRequiredReferences = 2;

  const defaultReferenceDetails = (required = false) => {
    return {
      first_name: '',
      last_name: '',
      company_name: '',
      phone_number: '',
      email: '',
      job_title: '',
      user_job_title: '',
      required,
      accepted_terms: false,
    };
  };

  const isReferenceFilled = (reference = {}) => {
    return Object.entries(reference).reduce((p, c) => p && (c[0] === 'required' || c[1] !== ''), true);
  };

  const defaultModalDetails = () => {
    return {
      show: false,
      i: 0,
      reference: defaultReferenceDetails(),
      /* copy reference object keys for errors */
      errors: Object.keys(defaultReferenceDetails()).reduce((p, c) => {p[c] = false; return p;}, {}),
      isUpdate: false,
    };
  };

  const [alreadyUploaded, setAlreadyUploaded] = useState(true);
  const [name, setName] = useState('');
  const [references, setReferences] = useState(Array(numRequiredReferences).fill(null).map(() => defaultReferenceDetails(true)));
  const [modal, setModal] = useState(defaultModalDetails());
  const [spinner, setSpinner] = useState(true);

  // get id should be last piece of url
  const id = useMemo(() => window.location.pathname.substring(1), []);

  // default api calls
  useEffect(() => {
    // check if upload already done
    if (id) {
      checkAlreadyUploaded(id).then((response) => {
        console.log('already uploaded references:\n', response.data);
        const data = response.data.data;
        setAlreadyUploaded(data.complete);
        setName(data.name);
      }).catch(logError).finally(() => setSpinner(false));
    } else {
      setSpinner(false);
    }
  }, [id]);

  const toggleReferenceModal = (i = 0, reference = null, isUpdate = false) => {
    if (reference !== null) {
      setModal({...defaultModalDetails(), show: true, i, reference: {...reference}, isUpdate});
    } else {
      setModal(defaultModalDetails());
    }
  };

  const onClickDeleteReference = (i = null) => {
    const l = references.length;
    if (typeof i === 'number' && i < l) {
      if (l <= numRequiredReferences) {
        references.splice(i, 1, defaultReferenceDetails(true));
      } else {
        references.splice(i, 1);
      }
      setReferences([...references]);
    }
  };

  const disableAddAdditionalReference = () => {
    for (const [i, r] of references.entries()) {
      if (!isReferenceFilled(r) && i < numRequiredReferences) {
        return true;
      }
    }

    return false;
  };

  const onClickAddAdditionalReference = () => {
    if (!disableAddAdditionalReference()) {
      references.push(defaultReferenceDetails());
      setReferences(references);
      const i = references.length-1;
      toggleReferenceModal(i, references[i]);
    }
  };

  const disableSubmit = () => {
    for (const [i, r] of references.entries()) {
      if (!isReferenceFilled(r) && i < numRequiredReferences) {
        return true;
      }
    }

    return false;
  };

  const onClickSubmit = () => {
    if (!disableSubmit()) {
      submitReferences(id, {references}).then(() => {
        setAlreadyUploaded(true);
      }).catch((errors) => {
        logError(errors);
        NotificationManager.error("Error submitting, try again.");
      });
    }
  };

  const modalOnClickX = () => {
    const l = references.length;
    if (l > numRequiredReferences && !isReferenceFilled(references[l-1])) {
      // remove last additional reference
      references.pop();
      setReferences([...references]);
    }
    toggleReferenceModal();
  };

  const modalOnChangeReferenceFields = (e) => {
    const name = e.target.name;
    modal.reference[name] = name === 'accepted_terms' ? e.target.checked : e.target.value;
    modal.errors[name] = false;
    setModal({...modal});
  };

  const modalOnClickAddReference = () => {
    let errored = false;
    Object.entries(modal.reference).forEach((kv) => {
      const name = kv[0];
      const value = kv[1];
      if (name !== 'required') {
        if (typeof value === 'string') {
          modal.reference[name] = value.trim();
          modal.errors[name] = modal.reference[name] === '';
          errored = errored || modal.errors[name];
        } else if (name === 'accepted_terms') {
          modal.errors[name] = value === false;
          errored = errored || modal.errors[name];
        }
      }
    });

    if (errored) {
      setModal({...modal});
    } else {
      references.splice(modal.i, 1, modal.reference);
      setReferences([...references]);
      setModal(defaultModalDetails());
    }
  };

  return (
    <div className="App">
      {spinner ?
        <div className="spinner"/>
        :
        <div>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
            <h1>
              {alreadyUploaded ?
                'References Successfully Submitted!'
                :
                'Provide References to Improve Your Application'
              }
            </h1>
          </header>

          {alreadyUploaded ?
            /* Complete Message Container */
            <div className="done-ctn">
              <h4>You've successfully submitted you references!</h4>
              <p>
                Thanks for submitting your references to UWork. Our team will validate your references if you're
                shortlisted for a job. In the meantime, keep applying on the app!
              </p>
            </div>
            :
            /* Upload References Container */
            <>
              <p>
                Hi{name ? ` ${name.split(' ')[0]}` : null}, thanks for applying to UWork! References are an important part in
                verifying your work history. Please provide at least two work related references for your application.
              </p>
              <div className="references-ctn">
                {/* Reference Rows */}
                {references.map((r, i) =>
                  <div className="reference-row" key={i}>
                    <div className="left-column">
                      <h3>Reference {i+1}{r.required && '*'}</h3>
                      {isReferenceFilled(r) ?
                        <div>
                          {r.first_name} {r.last_name} - {r.company_name} -&nbsp;
                          <span
                            className="edit-link"
                            onClick={() => toggleReferenceModal(i, r, true)}
                          >
                            Edit Reference
                          </span>
                        </div>
                        :
                        <div>
                          Please ensure this is a work related reference of a site supervisor or manager.
                        </div>
                      }
                    </div>
                    {isReferenceFilled(r) ?
                      <button
                        className="btn-red delete-btn"
                        onClick={() => onClickDeleteReference(i)}
                      >
                        {/* "DELETE" or "❌" in App.css */}
                      </button>
                      :
                      <button
                        className="btn-yellow add-btn"
                        onClick={() => toggleReferenceModal(i, r)}
                      >
                        {/* "ADD" or "+" in App.css */}
                      </button>
                    }
                  </div>
                )}

                {/* Add Additional Reference Row */}
                <div className="reference-row">
                  <div className="left-column">
                    <h3>Add Another Reference</h3>
                    <div>You must add two work references before adding more.</div>
                  </div>
                  <button
                    className={`btn-yellow additional-reference-btn${disableAddAdditionalReference() ? ' disabled' : ''}`}
                    onClick={onClickAddAdditionalReference}
                    disabled={disableAddAdditionalReference()}
                  >
                    {/* "ADD ADDITIONAL REFERENCE" and "＋" in App.css */}
                  </button>
                </div>

                {/* Submit Button Row */}
                <div className="reference-row submit">
                  <button
                    className={`btn-yellow filled${disableSubmit() ? ' disabled' : ''}`}
                    onClick={onClickSubmit}
                    disabled={disableSubmit()}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </>
          }

          {/* Reference Modal */}
          <Modal
            show={modal.show}
          >
            <button
              className="x-btn"
              onClick={modalOnClickX}
            >
              ❌
            </button>
            <h4>Reference {modal.i+1}</h4>
            <p>Please fill out the following information for your reference. Make sure that you have their permission
            to use them as a reference.
            </p>
            <div className="body-ctn">
              <div className="field-row">
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={modal.reference.first_name}
                  placeholder="John"
                  onChange={modalOnChangeReferenceFields}
                  className={modal.errors.first_name ? 'errored' : ''}
                />
              </div>
              <div className="field-row">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={modal.reference.last_name}
                  placeholder="Smith"
                  onChange={modalOnChangeReferenceFields}
                  className={modal.errors.last_name ? 'errored' : ''}
                />
              </div>
              <div className="field-row">
                <label htmlFor="company_name">Company Name</label>
                <input
                  type="text"
                  name="company_name"
                  value={modal.reference.company_name}
                  placeholder="Construction Ltd"
                  onChange={modalOnChangeReferenceFields}
                  className={modal.errors.company_name ? 'errored' : ''}
                />
              </div>
              <div className="field-row">
                <label htmlFor="email">Reference Email Address</label>
                <input
                  type="text"
                  name="email"
                  value={modal.reference.email}
                  placeholder="john.smith@constructionltd.com"
                  onChange={modalOnChangeReferenceFields}
                  className={modal.errors.email ? 'errored' : ''}
                />
              </div>
              <div className="field-row">
                <label htmlFor="phone_number">Reference Phone Number</label>
                <input
                  type="text"
                  name="phone_number"
                  value={modal.reference.phone_number}
                  placeholder="(226) 226-2226"
                  onChange={modalOnChangeReferenceFields}
                  className={modal.errors.phone_number ? 'errored' : ''}
                />
              </div>
              <div className="field-row">
                <label htmlFor="job_title">Reference Job Title</label>
                <input
                  type="text"
                  name="job_title"
                  value={modal.reference.job_title}
                  placeholder="Supervisor"
                  onChange={modalOnChangeReferenceFields}
                  className={modal.errors.job_title ? 'errored' : ''}
                />
              </div>
              <div className="field-row">
                <label htmlFor="job_title">Your Job Title</label>
                <input
                  type="text"
                  name="user_job_title"
                  value={modal.reference.user_job_title}
                  placeholder="Labourer"
                  onChange={modalOnChangeReferenceFields}
                  className={modal.errors.user_job_title ? 'errored' : ''}
                />
              </div>
              <div className="field-row">
                <input
                  type="checkbox"
                  name="accepted_terms"
                  checked={modal.reference.accepted_terms}
                  onChange={modalOnChangeReferenceFields}
                  className={modal.errors.accepted_terms ? 'errored' : ''}
                />
                <label htmlFor="accepted_terms">I{name ? `, ${name}` : ''} certify that the reference provided is
                accurate and is expecting to be contacted by UWork to verify my past employment.</label>
              </div>
            </div>
            <div className="footer-ctn">
              <button
                className="btn-yellow filled"
                onClick={modalOnClickAddReference}
              >
                {modal.isUpdate ? 'UPDATE' : 'ADD'} REFERENCE
              </button>
            </div>
          </Modal>
        </div>
      }
    </div>
  );
}

export default App;
